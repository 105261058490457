import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import Home from './containers/Home/index';
import Map22 from './containers/Map22/index';
import Map17 from './containers/Map17/index';
import Clean from './containers/Clean/index';
import ClaimTlm from './containers/ClaimTlm/index';
import Manage from './containers/Manage/index';
import SendWax from './containers/SendWax/index';
import Point from './containers/Point/index';
import MapPoint from './containers/MapPoint/index';
const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/map22", element: <Map22 /> },
    { path: "/mappoint", element: <MapPoint /> },
    { path: "/map17", element: <Map17 /> },
    { path: "/clean", element: <Clean /> },
    { path: "/claimTlm", element: <ClaimTlm /> },
    { path: "/manage", element: <Manage /> },
    { path: "/sendWax", element: <SendWax /> },
    { path: "/nftpoints", element: <Point /> },
    // ...
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;