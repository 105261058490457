import React, { useEffect, useState } from "react";

import "../../App.css";

import {
  Button,
  Row,
  Col,
  Space,
  Layout,
  notification,
  Menu,
  Input,
  Form,
  Select,
  Table,
  Progress,
  Badge,
  Card,
} from "antd";

import * as waxjs from "@waxio/waxjs/dist";
import axios from "axios";
import { menuItem } from "./../../menuData";

function Home() {
  var wax;
  const { Header, Footer, Content } = Layout;
  const [current, setCurrent] = useState("sendWax");

  const [waxJs, setWaxJs] = useState(null);
  const [userAccount, setUserAccount] = useState("");
  const [textToast, contextHolder] = notification.useNotification();
  const [noti, setNoti] = useState({ status: "", content: "" });

  const [nfts, setNfts] = useState({});
  const [] = useState(null);
  const [, setAccName] = useState(null);
  const [accList, setAccList] = useState([]);
  const [] = useState({
    bags: false,
    nfts: false,
    landId: false,
    accName: false,
  });
  const [wallet, setWallet] = useState([]);
  const [memoList, setMemoList] = useState([]);
  const [walletRecive, setWalletRecive] = useState('newuser.wax');
  const [waxAmount, setWaxAmount] = useState('5');
  const [] = useState([]);
  const [cpuNum, setCpuNum] = useState(240);
  const [netNum, setNetNum] = useState(0.2);
  const [ramNum, setRamNum] = useState(0.5);
  const [nftNum, setNftNum] = useState(3);
  const [nftName, setNftName] = useState(null);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    // checkAutoLoginAndLogin();
  }, []);
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {}, []);
  const columns = [
    {
      title: "stt",
      dataIndex: "key",
      width: 50,
    },
    {
      title: "wallet",
      dataIndex: "wallet",
    },
    {
      title: "CPU/NET/RAM",
      width: 180,
      render: (data) => {
        var cpu_use = data?.info?.cpu_limit?.used;
        var cpu_max = data?.info?.cpu_limit?.max;
        var width = Math.ceil((cpu_use * 100) / cpu_max);
        var net_use = data?.info?.net_limit?.used;
        var net_max = data?.info?.net_limit?.max;
        var width2 = Math.ceil((net_use * 100) / net_max);
        var width3 = Math.ceil(
          (data?.info?.ram_usage * 100) / data?.info?.ram_quota
        );

        return (
          <>
            <Progress
              strokeColor={{ "0%": "green", "100%": "red" }}
              percent={width}
              size="small"
            />
            <Progress
              strokeColor={{ "0%": "green", "100%": "red" }}
              percent={width2}
              size="small"
            />
            <Progress
              strokeColor={{ "0%": "green", "100%": "red" }}
              percent={width3}
              size="small"
            />
          </>
        );
      },
    },
    {
      title: "Stake",
      width: 160,
      render: (data) => {
        var cpu_weight = data?.info?.total_resources?.cpu_weight;
        var net_weight = data?.info?.total_resources?.net_weight;
        var num_cpu = cpu_weight
          ? Math.floor(cpu_weight.split(" ")[0] * 10) / 10
          : "-";
        var num_net = net_weight
          ? Math.floor(net_weight.split(" ")[0] * 10) / 10
          : "-";
        return (
          <>
            {`CPU ${num_cpu}WAX`} <br />
            {`NET ${num_net}WAX`}
          </>
        );
      },
    },
    {
      title: "Nfts",
      width: 100,
      render: (data) => {
        return (
          <>
            {data?.nftList && (
              <>
                {Object.keys(data.nftList).map(function (name) {
                  return <>{`${data.nftList[name].length} ${name}`}<br/></>;
                })}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Buy Ram",
      width: 200,
      align: "right",
      render: (data) => {
        return (
          <>
            <Input
              defaultValue={data.ram}
              value={data.ram}
              addonBefore="RAM"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.ram = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "110px",
                marginBottom: 5,
                marginRight: 5,
              }}
            />
            <Button onClick={() => {buyRamAction([data.wallet])}} type="primary" mt={2}>
              BUY
            </Button>
          </>
        );
      },
    },
    {
      title: "stake cpu - net",
      width: 240,
      align: "right",
      render: (data) => {
        return (
          <>
            <Input
              defaultValue={data.cpu}
              value={data.cpu}
              addonBefore="CPU"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.cpu = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              placeholder="Stake CPU"
              style={{
                width: "110px",
                marginBottom: 5,
              }}
            />
            <Input
              defaultValue={data.net}
              value={data.net}
              addonBefore="NET"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.net = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "100px",
                marginLeft: 5,
                marginBottom: 5,
              }}
            />
            <Button onClick={() => {stakeAction([data.wallet])}} type="primary" mt={2}>
              Stake
            </Button>
          </>
        );
      },
    },
    {
      title: `Send ${nftName}`,
      width: 300,
      render: (data) => {
        return (
          <>
            <Input
              defaultValue={data.nfts[0]}
              value={data.nfts[0]}
              addonBefore="nft1"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.nfts[0] = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "90px",
                marginBottom: 5,
              }}
            />
            <Input
              defaultValue={data.nfts[1]}
              value={data.nfts[1]}
              addonBefore="nft2"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.nfts[1] = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "90px",
                marginLeft: 5,
                marginBottom: 5,
              }}
            />
            <Input
              defaultValue={data.nfts[2]}
              value={data.nfts[2]}
              addonBefore="nft3"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.nfts[2] = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "90px",
                marginLeft: 5,
              }}
            />
            <Button onClick={() => {sendNftsAction([data.wallet])}} type="primary" mt={2}>
              {`Send`}
            </Button>
          </>
        );
      },
    },
    {
      title: "Unstake cpu - net",
      width: 240,
      align: "right",
      render: (data) => {
        return (
          <>
            <Input
              defaultValue={data?.cpuStake}
              value={data?.cpuStake}
              addonBefore="CPU"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.cpuStake = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "110px",
                marginBottom: 5,
              }}
            />
            <Input
              defaultValue={data?.netStake}
              value={data?.netStake}
              addonBefore="NET"
              onChange={(e) => {
                let acc = accList.map((x) => {
                  let y = x;
                  if (x.wallet == data.wallet) {
                    y.netStake = e.target.value;
                  }
                  return y;
                });
                setAccList(acc);
              }}
              style={{
                width: "100px",
                marginLeft: 5,
                marginBottom: 5,
              }}
            />
            <Button onClick={() => {unStakeAction([data.wallet])}} type="primary" mt={2}>
              UnStake
            </Button>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (data) => {
        return (
          <Button onClick={() => {reloadAction([data.wallet])}} type="primary" mt={2}>
            Reload
          </Button>
        );
      },
    },
  ];

  const buyRamAction = (wlList) => {
    let actionArray = [];
    wlList.map((wl) => { 
      let acc_info_list = accList.filter((x) => x.wallet == wl);
      if(acc_info_list.length > 0){
        let acc_info = acc_info_list[0];
        let number = acc_info?.ram;
        var num_tras = (Math.floor(parseFloat(number)*1000000))/1000000;
        var num_str = (""+num_tras).split('.');
        var num_trans = num_str.length == 1 ? num_str[0]+".00000000" : num_str.join('.')+"0".repeat(8-num_str[1].length)
          
        actionArray.push({
          account: 'eosio',
          name: 'buyram',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
            payer: userAccount,
            receiver: wl,
            quant: num_trans+" WAX"
          },
        });
      }
    });
    callAction(actionArray, waxJs, 'BOUGHT RAM DONE');
  }
  const stakeAction = (wlList) => {
    let actionArray = [];
    wlList.map((wl) => { 
      let acc_info_list = accList.filter((x) => x.wallet == wl);
      if(acc_info_list.length > 0){
          let acc_info = acc_info_list[0];
          let number1 = acc_info?.cpu;
          var num_tras1 = (Math.floor(parseFloat(number1)*1000000))/1000000;
          var num_str1 = (""+num_tras1).split('.');
          var num_trans1 = num_str1.length == 1 ? num_str1[0]+".00000000" : num_str1.join('.')+"0".repeat(8-num_str1[1].length)
          let number2 = acc_info?.net;;
          var num_tras2 = (Math.floor(parseFloat(number2)*1000000))/1000000;
          var num_str2 = (""+num_tras2).split('.');
          var num_trans2 = num_str2.length == 1 ? num_str2[0]+".00000000" : num_str2.join('.')+"0".repeat(8-num_str2[1].length)
        actionArray.push({
          account: 'eosio',
          name: 'delegatebw',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
            from: userAccount,
            receiver: wl,
            stake_cpu_quantity: num_trans1+" WAX",
            stake_net_quantity: num_trans2+" WAX",
            transfer: false
          },
        });
      }
    });
    callAction(actionArray, waxJs, `STAKE ${wlList.length} WALLET. DONE!`);
  }

  const unStakeAction = (wlList) => {
    let actionArray = [];
    wlList.map((wl) => { 
      let acc_info_list = accList.filter((x) => x.wallet == wl);
      if(acc_info_list.length > 0){
          let acc_info = acc_info_list[0];
          let number1 = acc_info?.cpuStake;
          var num_tras1 = (Math.floor(parseFloat(number1)*1000000))/1000000;
          var num_str1 = (""+num_tras1).split('.');
          var num_trans1 = num_str1.length == 1 ? num_str1[0]+".00000000" : num_str1.join('.')+"0".repeat(8-num_str1[1].length)
          let number2 = acc_info?.netStake;;
          var num_tras2 = (Math.floor(parseFloat(number2)*1000000))/1000000;
          var num_str2 = (""+num_tras2).split('.');
          var num_trans2 = num_str2.length == 1 ? num_str2[0]+".00000000" : num_str2.join('.')+"0".repeat(8-num_str2[1].length)
        actionArray.push({
          account: 'eosio',
          name: 'undelegatebw',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
            from: userAccount,
            receiver: wl,
            //receiver: account,
            unstake_cpu_quantity: num_trans1+" WAX",
            unstake_net_quantity: num_trans2+" WAX",
            transfer: false
          },
        });
      }
    });
    callAction(actionArray, waxJs, `UNSTAKE ${wlList.length} WALLET. DONE!`);
  }

  const sendNftsAction = (wlList) => {
    let actionArray = [];
    wlList.map((wl) => { 
      let acc_info_list = accList.filter((x) => x.wallet == wl);
      if(acc_info_list.length > 0){
        let acc_info = acc_info_list[0];
        actionArray.push({
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
          asset_ids: [...acc_info.nfts],
          from: userAccount,
          to: wl,
          memo: '',
          },
        });
      }
    });
    callAction(actionArray, waxJs, `SEND NFTS TO ${wlList.length} WALLET. DONE!`);
  }

  const callAction = async (actions, wax, alert) => {
    try {
      if (!wax) {
        await login();
      }
      await wax.api
        .transact(
          {
            actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 90,
          }
        )
        .then(() => {
          setNoti({
            status: "OK",
            content: alert ? alert: 'DONE',
          });
        });
    } catch (error) {
      setNoti({
        status: "Error",
        content: error,
      });
    }
  }

  const getNfts = async (userAccount, dataNft, page) => {
    if (userAccount) {
      var nft_rpc = nft_endpoint();
      await axios
        .get(
          nft_rpc +
            `/atomicassets/v1/assets?owner=` +
            userAccount +
            `&page=` +
            page +
            `&collection_name=alien.worlds&limit=1000`
        )
        .then((res) => {
          if (res.data.data) {
            
            if(res.data.data.length > 999){
              getNfts(userAccount,[...dataNft,...res.data.data], page + 1 );
            }
            if(dataNft.length > 0){
              setNfts(proccessNft([...dataNft,...res.data.data]));
            }
          }
        })
        .catch((error) => {
          setNoti({
            status: "Nfts loading Error",
            content: error,
          });
          getNfts(userAccount,dataNft, page );
        });
    }
  };

  const getNftAccount = (wl, acc) => {
    var nft_rpc = nft_endpoint();
    axios
      .get(
        nft_rpc +
          `/atomicassets/v1/assets?owner=` +
          wl +
          `&page=1&limit=50&sort=updated&order=desc`
      )
      .then((res) => {
        let acc_new = acc.map((x) => {
          if (x?.wallet == wl) {
            x.nftList = proccessNft(res.data.data);
          }
          return x;
        });
        setAccList(acc_new);
      })
      .catch(() => {
        getNftAccount(wl, acc);
      });
  };
  const proccessNft = (nftArr) => {
    let data_bag = {};
    nftArr.map((x) => {
      if (data_bag[x.name]) {
        data_bag[x.name].push(x.asset_id);
      } else {
        data_bag[x.name] = [x.asset_id];
      }
    });
    return data_bag;
  };
  const getInfo = (wl, acc) => {
    var rpc = rpc_endpoint();
    axios({
      method: "post",
      url: rpc + "/v1/chain/get_account",
      data: JSON.stringify({
        account_name: wl,
      }),
    })
      .then((res) => {
        let acc_new = acc.map((x) => {
          if (x?.wallet == wl) {
            x.info = res.data;
            var cpu_weight = res.data?.total_resources?.cpu_weight;
            var net_weight = res.data?.total_resources?.net_weight;
            var num_cpu = cpu_weight
              ? Math.floor(cpu_weight.split(" ")[0] * 10) / 10
              : "-";
            var num_net = net_weight
              ? Math.floor(net_weight.split(" ")[0] * 10) / 10
              : "-";
            x.cpuStake = num_cpu;
            x.netStake = num_net;
          }

          return x;
        });
        setAccList(acc_new);
      })
      .catch(() => {
        getInfo(wl, acc);
      });
  };

  const getAccName = async (userAccount) => {
    if (userAccount) {
      var rpc = rpc_endpoint();
      await axios({
        method: "post",
        url: rpc + "/v1/chain/get_table_rows",
        data: JSON.stringify({
          code: "federation",
          json: true,
          lower_bound: userAccount,
          scope: "federation",
          show_payer: false,
          table: "players",
          table_key: "",
          upper_bound: userAccount,
        }),
      })
        .then((res) => {
          if (res.data.rows[0]?.tag) {
            if (res.data.rows[0]?.tag != userAccount) {
              setAccName(res.data.rows[0]?.tag);
              localStorage.setItem(
                "accName",
                JSON.stringify({
                  value: res.data.rows[0]?.tag,
                })
              );
            }
          } else {
            setAccName(null);
          }
        })
        .catch(() => {
          getAccName(userAccount);
        });
    }
  };

  const rpc_endpoint = () => {
    var endpointList = [
      "https://wax.greymass.com",
      "https://wax.eosdac.io",
      "https://wax.api.eosnation.io",
      "https://wax.eu.eosamsterdam.net",
      "https://wax.eosrio.io",
      "https://api.wax.alohaeos.com",
    ];
    return endpointList[~~(Math.random() * (endpointList.length - 1))];
  };

  const nft_endpoint = () => {
    var nft_endpoint = [
      "https://aa.dapplica.io",
      "https://wax-aa.eu.eosamsterdam.net",
      "https://wax-aa.eosdac.io",
      // "https://atomic.wax.eosdetroit.io",
      "https://atomic.wax.tgg.gg",
      "https://atomic.3dkrender.com",
      "https://aa.wax.blacklusion.io"
    ];
    return nft_endpoint[~~(Math.random() * (nft_endpoint.length - 1))];
  };

  const handSubmit = () => {
    let actionArray = [];
    memoList.map((x) => { 
        let number = waxAmount;
        var num_tras = (Math.floor(parseFloat(number)*1000000))/1000000;
        var num_str = (""+num_tras).split('.');
        var num_trans = num_str.length == 1 ? num_str[0]+".00000000" : num_str.join('.')+"0".repeat(8-num_str[1].length)
          
        actionArray.push({
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
            from: userAccount,
            to: walletRecive,
            quantity: num_trans +" WAX",
            memo: x,
          }
        });
    });
    callAction(actionArray, waxJs, 'Send DONE');
    
  };

  const reloadAction = (acc) => {
    acc.map((wl) => {
      getInfo(wl, accList);
    });

    acc.map((wl) => {
      getNftAccount(wl, accList);
    });
  };

  async function login() {
    console.log("Logging in");

    try {
      wax = new waxjs.WaxJS({
        rpcEndpoint: rpc_endpoint(),
      });
      const userAccount2 = await wax.login();
      setUserAccount(userAccount2);
      setWaxJs(wax);
      getNfts(userAccount2,[], 1 );
    } catch (error) {
      setNoti({
        status: "Login Error",
        content: error,
      });
      console.error("User fail to login.", error);
    }
  }

  const openNotification = (placement) => {
    if (noti.status) {
      textToast.info({
        message: `${noti.status}`,
        description: `${noti.content}`,
        placement,
      });
    }
  };
  useEffect(() => {
    openNotification("topRight");
  }, [noti]);

  const Context = React.createContext({
    name: noti.content,
  });
  useEffect(() => {}, [userAccount]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size={[0, 48]}
    >
      <Layout className="layout">
        <Header style={headerStyle}>
          <Menu
            onClick={(e) => {
              console.log("click ", e);
              setCurrent(e.key);
            }}
            selectedKeys={[current]}
            mode="horizontal"
            items={[
              ...menuItem,
              {
                label: !userAccount && (
                  <Button type="primary" onClick={() => login()}>
                    Login
                  </Button>
                ),
                key: "login",
              },
            ]}
          />
        </Header>

        <Content style={contentStyle}>
          {userAccount && (
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{
                    width: "100%",
                  }}
                >
                  <Badge.Ribbon text={userAccount}>
                    <Card title={`NFTS LIST`} size="small">
                      {nfts && (
                        <>
                          {Object.keys(nfts).map(function (name) {
                            return <>{`${nfts[name].length}`}  <b> {`${name}`}</b>. </>;
                          })}
                        </>
                      )}
                    
                    </Card>
                  </Badge.Ribbon>
                </Space>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <Form form={form} layout="vertical">
                <Row gutter={16}>
                  <Col className="gutter-row" span={24}>
                    <Form.Item label="Memo List" name="Memo">
                      <Input
                        style={{
                          height: 60,
                        }}
                        onChange={(e) => {
                          let memo = e.target.value
                            .replace(/\n/g, " ")
                            .replace(/  /g, " ").split` `;
                            setMemoList(memo);
                        }}
                        placeholder="Adding multiple memo at once is supported by using space."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={8}>
                    <Input
                      addonBefore="Wallet send"
                      defaultValue={walletRecive}
                      value={walletRecive}
                      onChange={(e) => {
                        setWalletRecive(e.target.value);
                      }}
                      placeholder="Wallet send"
                    />
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Input
                      addonBefore="Amount"
                      defaultValue={waxAmount}
                      value={waxAmount}
                      onChange={(e) => {
                        setWaxAmount(e.target.value);
                      }}
                      placeholder="Wax amount"
                      addonAfter="WAX"
                    />
                  </Col>
                 <Col className="gutter-row" span={8}>
                    <Button
                      onClick={handSubmit}
                      type="primary"
                      size="large"
                      color="success"
                    >
                      Submit
                    </Button>
                  </Col>
                
                </Row>
              </Form>
            </Col>
            <Col className="gutter-row" span={24}></Col>
          </Row>
         <Context.Provider
            value={{
              name: "Notification",
            }}
          >
            {contextHolder}
          </Context.Provider>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          ©2023 Created by cleancodevietnam@gmail.com
        </Footer>
      </Layout>
    </Space>
  );
}
const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  padding: 10,
};
export default Home;
