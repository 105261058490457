export const menuItem = [
    {
        label: (
          <a href="/" target="_blank">
            Home
          </a>
        ),
        key: 'home',
    },
    {
        label: (
          <a href="/manage" target="_blank">
            Management
          </a>
        ),
        key: 'manage',
    },
    {
        label: (
          <a href="/sendWax" target="_blank">
            Send Wax
          </a>
        ),
        key: 'sendWax',
    },
];
